import request from '@/utils/request'

export default {
    eqList(params) {
        return request({
            url: '/v1/eqlist',
            method: 'get',
            params
        })
    },
    getEq(id) {
        return request({
            url: `/v1/eq/${id}`,
            method: 'get'
        })
    },
}
