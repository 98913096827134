<template>
  <div id="app">
    <van-nav-bar title="设备详情" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="common-wrap">
      <van-tabs v-model:active="active">
        <van-tab title="设备" name="1">
          <van-form>
            <van-field v-model="info.coding" label="设备编号" />
            <van-field v-model="info.replace_coding" label="资产编码" />
            <van-field v-model="info.name" label="设备名称" />
            <van-field v-model="info.dimension" label="外形尺寸" />
            <van-field v-model="info.weight" label="重量(T)" />
            <van-field v-model="info.model" label="型号" />
            <van-field v-model="info.spec" label="规格" />
            <van-field v-model="info.chuchang_date" label="出厂日期" />
            <van-field v-model="info.shiyong_date" label="始用年月" />
            <van-field v-model="info.power" label="总功率(KW)" />
            <van-field v-model="info.country" label="生产国别" />
            <van-field v-model="info.manufacturer" label="生产厂商" />
            <van-field v-model="info.factory_number" label="出厂编号" />
            <van-field v-model="eqStatus[info.status]" label="设备状态" />
            <van-field v-model="eqAttribute[info.attribute]" label="资产分类" />
            <van-field v-model="eqImportance[info.importance]" label="重要度" />
            <van-field v-model="info.original_value" label="原值" />
            <van-field v-model="info.guarantee" label="耐用年限" />
            <van-field v-model="info.first_category_v" label="设备一级类别" />
            <van-field v-model="info.category_id_v" label="设备二级类别" />
            <van-field v-model="info.mechanics" label="机械复杂系数" />
            <van-field v-model="info.electrical" label="电气复杂系数" />
          </van-form>
        </van-tab>
        <van-tab title="备件" name="2">
          <div v-if="info.parts_list && info.parts_list.length > 0" class="order-detail">
            <van-cell-group>
              <van-cell title="备件名称" value="型号" title-class="tl" />
              <div v-for="(item, index) in info.parts_list" :key="index">
                <van-cell :title="item.name" :value="item.spec" title-class="tl" />
              </div>
            </van-cell-group>
          </div>
          <div v-else class="list-no-data">暂无</div>
        </van-tab>
        <van-tab title="知识" name="3">
          <div v-if="info.parts_list && info.parts_list.length > 0" class="order-detail">
            <van-collapse v-model="knowledgeCategoryActive" accordion>
              <van-collapse-item v-for="(item, index) in info.knowledge_list" :title="item.name" :name="index+1" :key="index" style="text-align: left;">
                <div v-for="(listItem, listIndex) in item.list" :key="listIndex">
                  <div v-for="(knowledgeItem, knowledgeIndex) in listItem.l" :key="knowledgeIndex">
                    <li style="list-style: none;"><a :href="knowledgeItem.path" target="_blank">{{ knowledgeItem.name }}</a></li>
                  </div>
                </div>
              </van-collapse-item>
            </van-collapse>
          </div>
          <div v-else class="list-no-data">暂无</div>
        </van-tab>
        <van-tab title="故障树" name="4">
          <div v-if="info.cmParts && info.cmParts.length > 0" class="order-detail">
            <van-cell-group>
              <van-cell title="工单" value="维修部位" title-class="tl" />
              <div v-for="(item, index) in info.cmParts" :key="index">
                <van-cell :title="item.maintain_code" :value="item.position_text" title-class="tl" />
              </div>
            </van-cell-group>
          </div>
          <div v-else class="list-no-data">暂无</div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import API from '@/api/asset/asset'
import { eqStatus, eqAttribute, eqImportance } from  '@/utils/options'
export default {
  data() {
    return {
      active: '1',
      info: {
      },
      knowledgeCategoryActive: 0,
      eqStatus,
      eqAttribute,
      eqImportance
    }
  },
  created() {
    this.index()
  },
  methods: {
    index() {
      API.getEq(this.$route.params.id).then(res => {
        console.log(res.data.parts_list)
        this.info = res.data
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped>

</style>
